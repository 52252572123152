/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Flex, Image, Text } from 'rebass/styled-components'
import StyledLocation from '../styled/StyledLocation'
import Indicator from '../styled/Indicator'
import Selected from '../assets/selected.svg'

const Location = (props) => {
  const {
    isFocused,
    isSelected,
    location,
    onFocus,
    className,
    id,
    dataTvFocusLeft,
    dataTvFocusRight,
  } = props
  const [flag, setFlag] = useState(undefined)

  useEffect(() => {
    if (location) {
      try {
        const reqFlag = require(`../../../assets/flags/${location.countryCode.toLocaleLowerCase()}.png`)
        setFlag(reqFlag)
      } catch (err) {
        setFlag(null)
      }
    }
  }, [location])

  return (
    <StyledLocation
      className={className}
      data-id={location.id}
      data-type="location"
      isFocused={isFocused}
      isSelected={isSelected}
      onFocus={onFocus}
      onClick={onFocus}
      width={[1 / 6]}
      id={id}
      data-tv-focus-left={dataTvFocusLeft}
      data-tv-focus-right={dataTvFocusRight}
    >
      <Flex flexDirection="row" alignItems="center" flex="1 0">
        {flag && <Image src={flag} height="16px" />}
        <Text sx={{ fontSize: '24px', fontWeight: 700, color: 'text', mx: '10px' }}>
          {location.country}
        </Text>
      </Flex>
      <Text sx={{ color: 'text' }}>{location.city}</Text>
      {isSelected && <Indicator src={Selected} />}
    </StyledLocation>
  )
}

Location.defaultProps = {
  className: '',
  dataTvFocusLeft: '',
  dataTvFocusRight: '',
  isFocused: false,
  isSelected: false,
  onFocus: () => true,
}

Location.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.number,
    city: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
  }).isRequired,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  dataTvFocusLeft: PropTypes.string,
  dataTvFocusRight: PropTypes.string,
}

export default Location
