/* eslint-disable no-undef */
import * as Sentry from "@sentry/react";
import {
  simpleAuthUser,
  newAuthUser,
  migrateUser,
  getPurchaseToken,
  postB2BKey,
  fetchSupportLink,
} from './repository'
import { messengerAction } from '../messages/actions'

export const START_AUTH = 'user/START_AUTH'
export const USER_IS_LIVE = 'user/USER_IS_LIVE'
export const USER_NOT_LIVE = 'user/USER_NOT_LIVE'

export const USER_IS_MIGRATED = 'user/USER_IS_MIGRATED'
export const USER_FAILED_MIGRATION = 'user/USER_FAILED_MIGRATION'
export const USER_MIGRATION_SUCCESS = 'user/USER_MIGRATION_SUCCESS'

export const USER_IS_AUTHENTICATED = 'user/USER_IS_AUTHENTICATED'
export const USER_FAILED_AUTHENTICATION = 'user/USER_FAILED_AUTHENTICATION'
export const USER_AUTHENTICATION_SUCCESS = 'user/USER_AUTHENTICATION_SUCCESS'

export const USER_BUTTON_STATE = 'user/USER_BUTTON_STATE'
export const USER_MIGRATION_VIEW = 'user/USER_MIGRATION_VIEW'
export const GATE_SELECT_CURRENT = 'user/GATE_SELECT_CURRENT'
export const USER_NAVIGATION_RESET = 'user/USER_NAVIGATION_RESET'

export const PUSH_TO_PORTAL = 'user/PUSH_TO_PORTAL'

export const ACCOUNT_BUTTON_STATE = 'user/ACCOUNT_BUTTON_STATE'

export const TOGGLE_LOADING = 'user/TOGGLE_LOADING'
export const USER_SHOULD_LOGOUT = 'user/SHOULD_LOGOUT'
export const USER_LOGGED_OUT = 'user/LOGGED_OUT'
export const USER_GET_SUPPORT = 'user/GET_SUPPORT'

export const USER_TOKEN_SUCCESS = 'user/TOKEN_SUCCESS'
export const USER_TOKEN_FAILED = 'user/TOKEN_FAILED'
export const START_PURCHASE_PROCESS = 'user/START_PURCHASETOKEN_PROCESS'
export const USER_B2B_SUCCESS = 'user/B2B_TOKEN_SUCCESS'
export const USER_B2B_FAILED = 'user/B2B_TOKEN_FAILED'

export const GET_WEBINTERFACE_LINK_REQUEST = 'user/GET_WEBINTERFACE_LINK_REQUEST'
export const GET_WEBINTERFACE_LINK_SUCCESS = 'user/GET_WEBINTERFACE_LINK_SUCCESS'
export const GET_WEBINTERFACE_LINK_FAILED = 'user/GET_WEBINTERFACE_LINK_FAILED'

export function initLogout() {
  return {
    type: USER_LOGGED_OUT,
  }
}

export const userIsLive = () => {
  return {
    type: USER_IS_LIVE,
  }
}

export const startUserAuth = () => {
  return {
    type: START_AUTH,
  }
}

export const userIsAuthenticated = (data) => {
  return {
    type: USER_IS_AUTHENTICATED,
    payload: data,
  }
}

export const userAuthenticationFailed = (error) => {
  return {
    type: USER_FAILED_AUTHENTICATION,
    payload: error,
  }
}

export const userButtonState = (boolState) => {
  return {
    type: USER_BUTTON_STATE,
    payload: boolState,
  }
}

export const accountButtonState = (state) => {
  return {
    type: ACCOUNT_BUTTON_STATE,
    payload: state,
  }
}

export const startProcessPurchaseToken = () => {
  return {
    type: START_PURCHASE_PROCESS,
  }
}

export const successPurchaseToken = (payload) => {
  return {
    type: USER_TOKEN_SUCCESS,
    payload,
  }
}

export const successB2bToken = (payload) => {
  return {
    type: USER_B2B_SUCCESS,
    payload,
  }
}

export const failedPurchaseToken = (payload) => {
  return {
    type: USER_TOKEN_FAILED,
    payload,
  }
}

export const failedB2bToken = (payload) => {
  return {
    type: USER_B2B_FAILED,
    payload,
  }
}

export const initUserAuth = (username, localAccountId) => {
  return (dispatch) => {
    dispatch(startUserAuth())
    simpleAuthUser(username, localAccountId)
      .then((response) => {
        window.setTimeout(() => {
          dispatch(userIsAuthenticated(response.data))
          if (response?.data?.message)
            dispatch(messengerAction(response.data.message, 'initUserAuth'))
        }, 5000)
      })
      .catch((error) => {
        dispatch(userAuthenticationFailed(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'initUserAuth'))
        return false
      })
  }
}

export const initNewUser = (username, localAccountId) => {
  return (dispatch) => {
    dispatch(startUserAuth())
    newAuthUser(username, localAccountId)
      .then((response) => {
        window.setTimeout(() => {
          dispatch(userIsAuthenticated(response.data))
          if (response?.data?.message)
            dispatch(messengerAction(response.data.message, 'initNewAuth'))
        }, 5000)
      })
      .catch((error) => {
        dispatch(userAuthenticationFailed(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'initNewAuth'))
        return false
      })
  }
}

export const initMigrateUser = (user, formData) => {
  return (dispatch) => {
    dispatch(startUserAuth())
    migrateUser(user, formData)
      .then((response) => {
        window.setTimeout(() => {
          dispatch(userIsAuthenticated(response.data))
          if (response?.data?.message)
            dispatch(messengerAction(response.data.message, 'initMigrateUser'))
        }, 5000)
      })
      .catch((error) => {
        dispatch(userAuthenticationFailed(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'initMigrateUser'))
        return false
      })
  }
}

export const initB2BHandling = (user) => {
  return (dispatch) => {
    dispatch(startProcessPurchaseToken())
    getPurchaseToken(user)
      .then((response) => {
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'getPurchaseToken'))
        dispatch(successPurchaseToken(response.data.payload))
        if (typeof Windows !== 'undefined') {
          const store = Windows.Services.Store.StoreContext.getDefault()
          store
            .getCustomerPurchaseIdAsync(response.data.payload.access_token, null)
            .then((result) => {
              postB2BKey(result, user)
                .then((reply) => {
                  dispatch(successB2bToken(reply))
                  if (reply?.data?.message)
                    dispatch(messengerAction(reply.data.message, 'initB2BHandling'))
                })
                .catch((error) => {
                  dispatch(failedB2bToken(error))
                  if (error?.data?.message)
                    dispatch(messengerAction(error.data.message, 'initB2BHandling'))
                })
            })
            .catch((error) => {
              Sentry.captureException(error);
            })
        }
      })
      .catch((error) => {
        dispatch(failedPurchaseToken(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'getPurchaseToken'))
      })
  }
}

// Support Links

export function getSupportLinkStart() {
  return {
    type: GET_WEBINTERFACE_LINK_REQUEST,
  }
}

export function getSupportLinkSuccess(url) {
  return {
    type: GET_WEBINTERFACE_LINK_SUCCESS,
    payload: url,
  }
}

export function getSupportLinkFailure(error) {
  return {
    type: GET_WEBINTERFACE_LINK_FAILED,
    payload: error,
  }
}

export function getSupportLink(user) {
  return (dispatch) => {
    dispatch(getSupportLinkStart())
    fetchSupportLink(user)
      .then((response) => {
        dispatch(getSupportLinkSuccess(response.data.payload))
        if (response?.data?.message)
          dispatch(messengerAction(response.data.message, 'getSupportLink'))
      })
      .catch((error) => {
        dispatch(getSupportLinkFailure(error))
        if (error?.data?.message) dispatch(messengerAction(error.data.message, 'getSupportLink'))
      })
  }
}
