import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { findIndex, find, debounce } from 'lodash'
import { Box, Flex, Button, Text } from 'rebass/styled-components'

import { Layout } from '../../layouts'
import { GameListing, Locations } from '../../components'

import buttons from './controls'
import { setControls, unsetControls } from '../../redux/controls/actions'
import { getGamesForRent, getLocations, fetchConfiguration } from '../../redux/rent/actions'
import { startCartProcess } from '../../redux/cart/actions'
import { getListOfGames, getListOfLocations, getConfiguration } from '../../redux/rent/selectors'
import { getUser } from '../../redux/user/selectors'
import { getProductListings } from '../../redux/cart/selectors'
import resource from './messages/en.json'

const throttleGameSelection = debounce((gameElement, currentUser, dispatch) => {
  dispatch(getLocations(false, gameElement))
  dispatch(fetchConfiguration(false, gameElement, currentUser))
}, 500)

const Rent = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  i18n.addResources('en', 'rent', resource)

  const [selectedGame, setSelectedGame] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const configurationSelector = useSelector(getConfiguration)
  const [configuration, setConfiguration] = useState()
  const [orderButton, setOrderButton] = useState()

  useEffect(() => {
    setConfiguration(configurationSelector)
    if (configurationSelector) {
      setOrderButton(configurationSelector?.configurations[0]?.price)
    }
  }, [configurationSelector])

  const [lastFocusType, setLastFocusType] = useState({})

  const [games, setGames] = useState()
  const [locations, setLocations] = useState()

  const gamesSelector = useSelector(getListOfGames)
  const locationsSelector = useSelector(getListOfLocations)

  const userSelector = useSelector(getUser)
  const [currentUser, setCurrentUser] = useState(userSelector)

  const [activePurchaseButton, setActivePurchaseButton] = useState(false)

  useEffect(() => {
    setCurrentUser(userSelector)
  }, [userSelector])

  const resetTabIndex = () => {
    const focusables = document.getElementsByClassName('focusable')
    focusables.forEach((el, index) => {
      el.setAttribute('tabindex', index)
    })
  }
  const setButtons = useCallback(() => dispatch(setControls(buttons)), [dispatch])
  const resetButtons = useCallback(() => dispatch(unsetControls()), [dispatch])

  useEffect(() => {
    setButtons()
    return () => resetButtons()
  }, [setButtons, resetButtons])

  const onfocuschanging = (event) => {
    setLastFocusType(event.detail.nextFocusElement.dataset)
  }

  useEffect(() => {
    dispatch(getGamesForRent())
  }, [dispatch])

  const productListings = useSelector(getProductListings)

  useEffect(() => {
    const handleSelect = (event) => {
      const matchKey = findIndex(buttons, (o) => event.keyCode === o.keyMap)
      if (matchKey !== -1) {
        if (lastFocusType) {
          switch (lastFocusType.type) {
            case 'game':
              setSelectedGame(Number(lastFocusType.id))
              return true
            case 'location':
              setSelectedLocation(lastFocusType.id)
              return true
            case 'submitPurchase':
              dispatch(
                startCartProcess(
                  selectedLocation,
                  {
                    ...configuration.configurations[0],
                  },
                  currentUser,
                  productListings
                )
              )
              return true
            default:
              break
          }
        }
      }
      return true
    }
    document.addEventListener('keydown', handleSelect)

    return () => {
      document.removeEventListener('keydown', handleSelect)
    }
  }, [dispatch, selectedLocation, lastFocusType, currentUser, configuration, productListings])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { TVJS } = window
      TVJS.DirectionalNavigation.focusRoot = document.getElementById('rent')
      TVJS.DirectionalNavigation.addEventListener('focuschanging', onfocuschanging)
    }
    return () => {
      if (typeof window !== 'undefined') {
        const { TVJS } = window
        TVJS.DirectionalNavigation.removeEventListener('focuschanging', onfocuschanging)
      }
    }
  }, [])

  // On Store Update
  useEffect(() => {
    const { TVJS } = window
    setGames(gamesSelector)
    resetTabIndex()
    const initial = TVJS.DirectionalNavigation.findNextFocusElement()
    if (initial) {
      initial.focus()
    }
  }, [gamesSelector])

  useEffect(() => {
    setLocations(locationsSelector)
    resetTabIndex()
  }, [locationsSelector])

  // On Selection Update
  useEffect(() => {
    const gameElement = find(games, (o) => o.id === selectedGame)
    if (gameElement) {
      setLocations([])
      setSelectedLocation(null)
      // dispatch(getLocations(false, gameElement))
      // dispatch(fetchConfiguration(false, gameElement, currentUser))
      throttleGameSelection(gameElement, currentUser, dispatch)
    }
  }, [selectedGame, currentUser, dispatch, games])

  useEffect(() => {
    if (selectedGame && selectedLocation) setActivePurchaseButton(true)
  }, [selectedGame, selectedLocation])

  const setupCartProcess = useCallback(() => {
    dispatch(
      startCartProcess(
        selectedLocation,
        {
          ...configuration.configurations[0],
        },
        currentUser,
        productListings
      )
    )
  }, [dispatch, configuration, selectedLocation, currentUser, productListings])

  return (
    <Layout>
      <Flex flexDirection="column" id="rent">
        <Flex sx={{ padding: '0 100px 0 100px' }}>
          <Text variant="headlineSmaller">{i18n.t('rent:headline')}</Text>
        </Flex>
        <GameListing
          id="gameListing"
          games={games}
          selectId={selectedGame && selectedGame}
          select={(id) => setSelectedGame(id)}
        />
        <Locations
          id="locationListing"
          locations={locations}
          selectLocation={selectedLocation && selectedLocation}
          select={(id) => setSelectedLocation(id)}
          disabled
        />
        <Box px="100px">
          <Button
            width={orderButton ? 'auto' : [1 / 6]}
            variant={activePurchaseButton ? 'submit' : 'disabled'}
            disabled={!activePurchaseButton}
            data-type="submitPurchase"
            onClick={() => setupCartProcess()}
          >
            {orderButton ? i18n.t('rent:orderFor', { price: orderButton }) : i18n.t('rent:order')}
          </Button>
        </Box>
      </Flex>
    </Layout>
  )
}

export default Rent
