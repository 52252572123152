import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { Flex } from 'rebass/styled-components'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Container from './styled/Container'
import { ControlState } from '../../components'

const Login = ({ children }) => {
  const buttons = useSelector((state) => state.controls.buttons)

  return (
    <Flex sx={{ overflow: 'hidden' }}>
      <Container flexDirection="row">{children}</Container>
      {buttons.length > 0 && (
        <Flex
          style={{
            padding: '30px',
            position: 'absolute',
            left: '70px',
            bottom: '40px',
            zIndex: '1100',
          }}
        >
          <ControlState />
          <ToastContainer />
        </Flex>
      )}
    </Flex>
  )
}

Login.defaultProps = {
  children: {},
}

Login.propTypes = {
  children: PropTypes.node,
}

export default Login
