import styled, { css } from 'styled-components'
import { Flex } from 'rebass/styled-components'

import Arrow from '../assets/arrow_ddd.svg'

const Wrapper = styled(Flex)(
  (props) => css`
    position: relative;
    padding: 5px 0 5px 0;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scroll-margin: 0;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start end;
    ::-webkit-scrollbar {
      display: none;
    }
    :before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      width: 40px;
      height: 33%;
      background-image: url(${Arrow});
      background-repeat: no-repeat;
      /* background-image: linear-gradient(to left, transparent, ${props.theme.colors.background}); */
      opacity: 1;
      transform: translateY(calc(-50% + 10px));
      z-index: 1001;
    }
    :after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      width: 40px;
      height: 33%;
      background-image: url(${Arrow});
      background-repeat: no-repeat;
      /* background-image: linear-gradient(to right, transparent, ${props.theme.colors.background}); */
      opacity: 1;
      transform: rotate(180deg) translateY(calc(50% - 10px));
    }
  `
)

export default Wrapper
