/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Flex, Text } from 'rebass/styled-components'
import Location from './components/Location'
import Loading from './components/Loading'
import Wrapper from './styled/Wrapper'
import FocusWrap from './styled/FocusWrap'

import resource from './messages/en'

const Locations = ({ locations, selectLocation, select }) => {
  const { i18n, t } = useTranslation()
  i18n.addResources('en', 'locations', resource)
  const focusRef = useRef(null)
  const [focused, setFocused] = useState()

  const handleFocus = (location, index) => {
    setFocused(location)
    select(location.id)
    if (index === 0) {
      focusRef.current.scrollLeft = 0
      focusRef.current.style.transform = 'none'
      focusRef.current.style.paddingRight = '100px'
      focusRef.current.style.transition = 'all 250ms linear'
    } else if (index === 3) {
      if (focusRef.current.style.transform !== 'none') {
        focusRef.current.scrollLeft = 0
        focusRef.current.style.transform = 'none'
        focusRef.current.style.paddingRight = '100px'
        focusRef.current.style.transition = 'all 250ms linear'
        if (typeof focusRef.current.scrollTo === 'function') {
          focusRef.current.scrollTo(0, 0)
        }
      }
    } else if (index === locations.length - 1) {
      focusRef.current.style.transform = 'translateX(-100px)'
      focusRef.current.style.paddingRight = '120px'
      focusRef.current.style.transition = 'all 250ms linear'
    }
  }

  return (
    <Flex flexDirection="column">
      <Text variant="uppercaseSmall" px="100px" pt="20px">
        {t('locations:selectLocation')}
      </Text>
      <Wrapper px="100px">
        <FocusWrap ref={focusRef}>
          {locations &&
            locations.map((location, index) => (
              <Location
                className="focusable"
                key={`location-${location.countryCode}-${location.key}`}
                id={`location-${index}`}
                location={location}
                sx={{ borderColor: 'white' }}
                isSelected={selectLocation && parseInt(selectLocation, 10) === location.id}
                onFocus={() => handleFocus(location, index)}
                onClick={() => handleFocus(location, index)}
                isFocused={focused && focused.id === location.id}
                dataTvFocusLeft={index === 0 ? `#location-${locations.length - 1}` : ''}
                dataTvFocusRight={index === locations.length - 1 ? '#location-0' : ''}
              />
            ))}
          {!locations.length && <Loading />}
        </FocusWrap>
      </Wrapper>
    </Flex>
  )
}

Locations.defaultProps = {
  locations: [],
  selectLocation: 0,
}

Locations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.any),
  selectLocation: PropTypes.number,
  select: PropTypes.func.isRequired,
}

export default Locations
