import styled, { css } from 'styled-components'
import { Card } from 'rebass/styled-components'

const StyledLocation = styled(Card)(
  (props) => css`
    background-color: ${props.theme.colors.primary};
    padding: 18px;
    margin-right: 28px;
    border-radius: 5px;
    border: 2px solid transparent;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;

    ${(props.isFocused || props.isSelected) &&
    css`
      border: 2px solid ${props.theme.colors.brand};
    `}

    :focus {
      outline: none;
    }
  `
)

export default StyledLocation
